// @flow

import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { useIntl } from 'gatsby-plugin-intl'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import Layout from '../../../components/Layout/index'
import Header from '../../../modules/Header'
import Footer from '../../../modules/Footer'
import CaseStudy from '../../../components/CaseStudy'
import SEO from '../../../components/seo'
import messages from '../../../messages/pageTitles.lang'
import dimensionMessages from '../../../messages/dimensions.lang'
import metaDescription from '../../../messages/pageMetaDescriptions.lang'

import baseStyles from './lufthansa-holidays.scss'
import baseCaseMessages from '../../../components/CaseStudy/CaseStudy.lang'
import caseMessages from '../../../components/CaseStudy/messages/LufthansaHolidays/LufthansaHolidays.lang'

/* eslint-disable max-len */
const heroBgDesktop = (
  <StaticImage src="../../../images/casestudy/herobgdesktop.jpg" alt="" objectFit="cover" className="image" />
)
const heroImgDesktop = (
  <StaticImage
    src="../../../images/casestudy/lufthansaholidays/heroimg.png"
    alt=""
    objectFit="contain"
    className="image"
  />
)
const welcomeImg = (
  <StaticImage
    src="../../../images/casestudy/lufthansaholidays/welcome.png"
    alt=""
    objectFit="contain"
    className="image"
  />
)
const infoImg = (
  <StaticImage src="../../../images/casestudy/lufthansaholidays/wls.png" alt="" objectFit="contain" className="image" />
)
const func1Img = (
  <StaticImage
    src="../../../images/casestudy/lufthansaholidays/func1.png"
    alt=""
    objectFit="contain"
    className="image"
  />
)
const func2Img = (
  <StaticImage
    src="../../../images/casestudy/lufthansaholidays/func2.png"
    alt=""
    objectFit="contain"
    className="image"
  />
)
const func3Img = (
  <StaticImage
    src="../../../images/casestudy/lufthansaholidays/func3.png"
    alt=""
    objectFit="contain"
    className="image"
  />
)
const techImg = (
  <StaticImage
    src="../../../images/casestudy/lufthansaholidays/tech.png"
    alt=""
    objectFit="contain"
    className="image"
  />
)
const advantagesImg = (
  <StaticImage
    src="../../../images/casestudy/lufthansaholidays/advantages.png"
    alt=""
    objectFit="contain"
    className="image"
  />
)
const freshMSImg = (
  <StaticImage
    src="../../../images/casestudy/lufthansaholidays/freshMS.png"
    alt=""
    objectFit="contain"
    className="image"
  />
)
const feedbackIckstadt = (
  <StaticImage
    src="../../../images/casestudy/lufthansaholidays/client-ickstadt.png"
    alt=""
    objectFit="contain"
    className="image"
  />
)
const feedbackKoegel = (
  <StaticImage
    src="../../../images/casestudy/lufthansaholidays/client-koegel.png"
    alt=""
    objectFit="contain"
    className="image"
  />
)
const feedbackSchoeler = (
  <StaticImage
    src="../../../images/casestudy/lufthansaholidays/client-schoeler.png"
    alt=""
    objectFit="contain"
    className="image"
  />
)
const feedback01 = (
  <StaticImage
    src="../../../images/casestudy/lufthansaholidays/customer-01.png"
    alt=""
    objectFit="contain"
    className="image"
  />
)
const feedback02 = (
  <StaticImage
    src="../../../images/casestudy/lufthansaholidays/customer-02.png"
    alt=""
    objectFit="contain"
    className="image"
  />
)
const feedback03 = (
  <StaticImage
    src="../../../images/casestudy/lufthansaholidays/customer-03.png"
    alt=""
    objectFit="contain"
    className="image"
  />
)

// wls logos
const fiveStar = (
  <StaticImage
    src="../../../images/casestudy/lufthansaholidays/wls/5star.png"
    alt=""
    objectFit="contain"
    className="image"
  />
)
const condor = (
  <StaticImage
    src="../../../images/casestudy/lufthansaholidays/wls/condor.png"
    alt=""
    objectFit="contain"
    className="image"
  />
)
const exclusive = (
  <StaticImage
    src="../../../images/casestudy/lufthansaholidays/wls/exclusive.png"
    alt=""
    objectFit="contain"
    className="image"
  />
)
const globista = (
  <StaticImage
    src="../../../images/casestudy/lufthansaholidays/wls/globista.png"
    alt=""
    objectFit="contain"
    className="image"
  />
)
const guru = (
  <StaticImage
    src="../../../images/casestudy/lufthansaholidays/wls/guru.png"
    alt=""
    objectFit="contain"
    className="image"
  />
)
const hlx = (
  <StaticImage
    src="../../../images/casestudy/lufthansaholidays/wls/hlx.png"
    alt=""
    objectFit="contain"
    className="image"
  />
)
const lhh = (
  <StaticImage
    src="../../../images/casestudy/lufthansaholidays/wls/lhh.png"
    alt=""
    objectFit="contain"
    className="image"
  />
)
const nrj = (
  <StaticImage
    src="../../../images/casestudy/lufthansaholidays/wls/nrj.png"
    alt=""
    objectFit="contain"
    className="image"
  />
)
const piratten = (
  <StaticImage
    src="../../../images/casestudy/lufthansaholidays/wls/piratten.png"
    alt=""
    objectFit="contain"
    className="image"
  />
)
const se = (
  <StaticImage
    src="../../../images/casestudy/lufthansaholidays/wls/se.png"
    alt=""
    objectFit="contain"
    className="image"
  />
)
const swiss = (
  <StaticImage
    src="../../../images/casestudy/lufthansaholidays/wls/swiss.png"
    alt=""
    objectFit="contain"
    className="image"
  />
)
const tchibo = (
  <StaticImage
    src="../../../images/casestudy/lufthansaholidays/wls/tchibo.png"
    alt=""
    objectFit="contain"
    className="image"
  />
)
const travelzoo = (
  <StaticImage
    src="../../../images/casestudy/lufthansaholidays/wls/travelzoo.png"
    alt=""
    objectFit="contain"
    className="image"
  />
)

import welcomeLogoSrc from '../../../images/casestudy/lufthansaholidays/logo.svg'
const welcomeLogo = <img src={welcomeLogoSrc} alt="" className="image" />
import welcomeLogoMobileSrc from '../../../images/casestudy/lufthansaholidays/logo-lufthansa-holidays.svg'
const welcomeLogoMobile = <img src={welcomeLogoMobileSrc} alt="" className="image" />
/* eslint-enable max-len */

import { projectsList } from '../../../components/CaseStudy/helpers'

type Props = {
  ...StyleProps
}

const data = {
  // link: 'https://www.lufthansaholidays.com/',
  name: 'Lufthansa Holidays',
  heroTeaser: {
    bg: {
      imageDesktop: heroBgDesktop
    },
    img: heroImgDesktop,
    title: projectsList.lhh.title,
    description: projectsList.lhh.description
  },
  facts: [
    {
      title: baseCaseMessages.challenge,
      text: caseMessages.fact1Text,
      color: 'tertiary',
      icon: 'icon-eye'
    },
    {
      title: baseCaseMessages.solution,
      text: caseMessages.fact2Text,
      color: 'secondary',
      icon: 'icon-board'
    },
    {
      title: baseCaseMessages.approach,
      text: caseMessages.fact3Text,
      color: 'accent',
      icon: 'icon-devices'
    }
  ],
  welcome: {
    logo: welcomeLogo,
    text: caseMessages.welcomeText,
    img: welcomeImg,
    logoMobile: welcomeLogoMobile
  },
  logosSlider: {
    title: caseMessages.logosSliderTitle,
    list: [
      {
        link: '',
        logo: fiveStar
      },
      {
        link: '',
        logo: condor
      },
      {
        link: '',
        logo: exclusive
      },
      {
        link: '',
        logo: globista
      },
      {
        link: '',
        logo: guru
      },
      {
        link: '',
        logo: hlx
      },
      {
        link: '',
        logo: lhh
      },
      {
        link: '',
        logo: nrj
      },
      {
        link: '',
        logo: piratten
      },
      {
        link: '',
        logo: se
      },
      {
        link: '',
        logo: swiss
      },
      {
        link: '',
        logo: tchibo
      },
      {
        logo: travelzoo
      }
    ]
  },
  info: {
    img: infoImg,
    title: caseMessages.infoTitle,
    list: [caseMessages.infoItem1, caseMessages.infoItem2, caseMessages.infoItem3]
  },
  stats: [
    {
      number: 500000,
      numberDimension: '',
      value: caseMessages.stat1Value,
      color: 'primary',
      icon: 'icon-user-group'
    },
    {
      number: 130,
      numberDimension: '',
      value: caseMessages.stat2Value,
      color: 'tertiary',
      icon: 'icon-beach-access'
    },
    {
      number: 150,
      numberDimension: dimensionMessages.dimensionMillion,
      value: caseMessages.stat3Value,
      color: 'secondary',
      icon: 'icon-euro'
    },
    {
      number: 1000,
      numberDimension: '',
      value: caseMessages.stat4Value,
      color: 'accent',
      icon: 'icon-plane'
    }
  ],
  functionalitiesTitle: baseCaseMessages.featuresTitle,
  functionalitiesListInversed: true,
  functionalities: [
    {
      title: caseMessages.functionality1Title,
      list: [caseMessages.functionality1Item1, caseMessages.functionality1Item2, caseMessages.functionality1Item3],
      img: func1Img
    },
    {
      title: caseMessages.functionality2Title,
      list: [
        caseMessages.functionality2Item1,
        caseMessages.functionality2Item2,
        caseMessages.functionality2Item3,
        caseMessages.functionality2Item4
      ],
      img: func2Img
    },
    {
      title: caseMessages.functionality3Title,
      list: [caseMessages.functionality3Item1, caseMessages.functionality3Item2, caseMessages.functionality3Item3],
      img: func3Img
    }
  ],
  roadmapTitle: baseCaseMessages.workflow,
  roadmap: [
    {
      title: baseCaseMessages.roadmapWorkshop,
      timeValue: '',
      timeMeasure: '',
      color: 'primary',
      icon: 'icon-flag'
    },
    {
      title: baseCaseMessages.roadmapConception,
      timeValue: '',
      timeMeasure: '',
      color: 'tertiary',
      icon: 'icon-quality'
    },
    {
      title: baseCaseMessages.roadmapDevelopment,
      timeValue: '',
      timeMeasure: '',
      color: 'secondary',
      icon: 'icon-memory'
    },
    {
      title: baseCaseMessages.roadmapRelease,
      timeLabel: '',
      timeValue: '',
      timeMeasure: '',
      color: 'accent',
      icon: 'icon-heart-filled'
    }
  ],
  brand: {
    title: baseCaseMessages.featuresDoneTitle,
    tech: {
      title: baseCaseMessages.techTitle,
      list: [
        baseCaseMessages.techPerfomance,
        baseCaseMessages.techScalable,
        baseCaseMessages.techFlexible,
        baseCaseMessages.techArt
      ],
      img: techImg
    },
    advantages: {
      title: caseMessages.advantagesTitle,
      list: [caseMessages.advantagesOffers, caseMessages.partnerPage, caseMessages.sharedConfigs],
      img: advantagesImg
    },
    action: {
      title: caseMessages.freshMsTitle,
      text: caseMessages.freshMsText,
      img: freshMSImg
    },
    design: {
      title: baseCaseMessages.designTitle,
      text: baseCaseMessages.designText,
      list: [baseCaseMessages.designItemInnovative, baseCaseMessages.designItemDevices],
      img: projectsList.lhh.overviewImage2
    }
  },
  feedback: [
    {
      text: caseMessages.feedbackText,
      from: caseMessages.feedbackFrom,
      img: feedbackSchoeler
    },
    {
      text: caseMessages.feedbackText2,
      from: caseMessages.feedbackFrom2,
      img: feedbackKoegel
    },
    {
      text: caseMessages.feedbackText3,
      from: caseMessages.feedbackFrom3,
      img: feedbackIckstadt
    }
  ],
  reviews: [
    {
      img: feedback01,
      name: 'Cem',
      from: 'Trustpilot',
      title: 'Best travel portal',
      rating: [1, 2, 3, 4, 5],
      // eslint-disable-next-line max-len
      text:
        'Best travel portal i have ever seen. Super easy to use. Top hotels and travel.The first travel portal that I find first class. I booked for 4 persons.'
    },
    {
      img: feedback02,
      name: 'Liska W.',
      from: 'Google Review',
      title: 'Great variety',
      rating: [1, 2, 3, 4, 5],
      // eslint-disable-next-line max-len
      text:
        'Great variety of offers and a very good overview. Nice and competent employees. So far I have only had good experiences.'
    },
    {
      img: feedback03,
      name: 'Tim',
      from: 'erfahrungen24',
      title: 'Pretty cheap city trips',
      rating: [1, 2, 3, 4, 5],
      text: 'I was surprised by the wide range of city trips offered by Lufthansa Holidays'
    }
  ] // ,
  // check: {
  //   img: projectsList.lhh.marketingImage,
  //   title: baseCaseMessages.checkTitle,
  //   text: baseCaseMessages.checkText
  // }
}

const CaseLHH = ({ styles }: Props) => {
  const { formatMessage, locale } = useIntl()

  return (
    <Layout footer={<Footer />} header={<Header withTeaser />} styles={styles.root}>
      <SEO
        title={formatMessage(messages.TitleCaseStudyHLX)}
        description={formatMessage(metaDescription.CaseStudyHlxDescription)}
        lang={locale}
      />
      <CaseStudy data={data} styles={styles.case} />
    </Layout>
  )
}

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(CaseLHH)
